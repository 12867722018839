<template>
    <div class="CreateVideo">
        <div class="header">
            <div class="model">v{{ type }}数字人</div>
            <div class="points">| {{ number }}点/分钟</div>
            <div class="warn" v-if="type == 5">v5合成，上传的视频仅支持mp4和mov格式（区分大小写）</div>
        </div>
        <div class="borderBox">
            <!-- 视频名称 -->
            <div class="name">
                <div class="title"><span>*</span>视频名称</div>
                <el-input v-model="nameValue" maxlength="15" show-word-limit placeholder="请输入内容"></el-input>
            </div>


            <!-- 音频选择 -->
            <div class="audioSelection">
                <div class="title"><span>*</span>音频选择</div>
                <div class="playAudio" v-if="audioItem.audio_url">
                    <audioPlayer :aPath="audioItem.audio_url" />
                </div>
                <div class="selectAudio" v-if="!audioItem.audio_url">
                    <div></div>
                    <div class="button" @click="audioDrawer = true">选择音频</div>
                </div>
                <div class="selectAudio" v-if="audioItem.audio_url">
                    <div></div>
                    <div class="button" @click="audioDrawer = true">重新选择</div>
                </div>

            </div>

            <!-- 用户选择V5线路时，需直接上传视频 -->
            <div class="v5" v-if="type == 5">
                <div class="backplaneVideo">
                    <div class="title"><span>*</span>视频上传</div>
                    <div :style="{ 'display': (videoPathOss == '' ? 'block' : 'none') }">
                        <div class="uploadBox">
                            <div class="upload">
                                <videoUpload :showProgress="showProgress" ref="videoUpload" @videoPath="videoPath" />
                            </div>
                        </div>

                    </div>

                    <div :style="{ 'display': (videoPathOss !== '' ? 'block' : 'none') }">
                        <div class="video">
                            <div class="videoBox"><video :src="videoPathOss" controls></video></div>
                            <div class="reupload" @click="reupload">
                                <div class="text">重新上传视频</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <!-- 形象选择 -->
            <div class="imageSelection" v-if="type !== 5">
                <div class="title"><span>*</span>形象选择</div>
                <div v-if="imageItem.id !== undefined">
                    <imageModule :item="imageItem" />
                    <div @click="anewSelect" style="text-align: center; font-size: 16px;cursor: pointer;"
                        class="anewSelect">重新选择</div>
                </div>
                <div v-if="imageItem.id == undefined" @click="imageDrawer = true" class="positionBox">
                    <div> <img src="../assets/xuanze.png" alt=""></div>
                    <div class="text" @click.stop="goImage">选择形象，如没有请前往资产 <span>数字人</span> </div>
                </div>
                <!-- <div class="button" >选择形象</div> -->
            </div>
        </div>



        <!-- 音频抽屉 -->
        <el-drawer custom-class='audioDrawer' :destroy-on-close="true" :visible.sync="audioDrawer" direction="rtl">
            <audioDrawer ref="audioDrawerRefresh" :audioDrawer="audioDrawer" @audioDra="audioDra" @audioShow="audioShow"
                @audioData="audioData" />
        </el-drawer>

        <!-- 文本合成音频弹框 -->
        <el-dialog title="合成音频" @close='handleCancle' @open="openCancle" :visible.sync="audioPopup" width="1050px">
            <audioPopup @popup="popup" :audioPopShow="audioPopShow" />
        </el-dialog>


        <!-- 形象抽屉 -->
        <el-drawer custom-class="imageDrawer" :visible.sync="imageDrawer" direction="rtl">
            <imageDrawer @selectImageItem="selectImageItem" @imageDra="imageDra" :type="type" />
        </el-drawer>


        <div class="submit">
            <div class="but" @click="submit">提交</div>
            <div class="agreement">
                <el-checkbox v-model="signinChecked">我确认我拥有提交合成视频的所有必要权力，我保证不会用合成视频创作违法内容，并且会严格遵守
                </el-checkbox><span @click.stop="openAgreement">《数字人视频合成服务协议》</span>
            </div>
        </div>
        
        <el-dialog title="数字人视频合成服务协议" custom-class="agreementShow" :visible.sync="agreementShow" width="600px">
            <div class="text">
                <VideoSynthesisProtocol />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="consentAgreement">阅读并同意</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import audioDrawer from './cvMain/audioDrawer.vue'//音频抽屉
import audioPopup from './cvMain/audioPopup.vue'//文本合成音频弹框
import imageDrawer from './cvMain/imageDrawer.vue';//形象抽屉

import imageModule from '../../components/digitalImageModule/imageModule.vue'//形象
import audioPlayer from '../../components/AudioPlayer.vue'//音频播放
import videoUpload from '../../components/videoUpload.vue'//视频上传 - v5使用

import VideoSynthesisProtocol from '../../components/agreement/CompositeWork.vue'
import { createMyWork } from '../../api/api'
export default {
    components: {
        audioDrawer,
        audioPopup,
        imageDrawer,
        imageModule,
        audioPlayer,
        videoUpload,
        VideoSynthesisProtocol
    },
    props: {
        type: {
            type: Number,
            default: 0
        },
        number: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            nameValue: '',//视频名称
            audioDrawer: false,//控制音频抽屉显示隐藏
            audioPopup: false,//控制文本合成音频弹框
            imageDrawer: false,//控制形象抽屉显示隐藏

            videoPathOss: '',//用户选择V5线路时上传到OSS返回的视频连接
            imageItem: {},//选中的形象
            audioItem: {},//选择的音频的数据

            signinChecked: false,//协议

            showProgress: false,//重新上传视频-处理上一条视频

            time: null,

            audioPopShow: false,//用于弹框关闭时，关闭子组件音频播放

            agreementShow: false,//协议
        }
    },
    methods: {
        //控制文本合成音频弹框
        audioShow(e) {
            this.audioPopup = e
        },
        popup(e) {
            this.audioPopup = e
        },
        // 用户选择V5线路时上传到OSS返回的视频连接
        videoPath(e) {
            this.videoPathOss = e
        },
        // 重新上传视频
        reupload() {
            this.videoPathOss = ''
            this.showProgress = false
            this.$refs.videoUpload.$refs.videoUpload.clearFiles(); // 方法2:直接调用
        },
        // 选择的音频
        audioData(e) {
            this.audioItem = e
        },
        // 选择的形象
        selectImageItem(e) {
            console.log(e)
            this.imageItem = e
        },
        // 前往克隆
        goImage() {
            this.$router.push('/Home/digitalMan/digitalCloning')
        },
        // 重新选择形象
        anewSelect() {
            this.imageItem = {}
            this.imageDrawer = true
        },
        // 关闭形象抽屉
        imageDra(e) {
            this.imageDrawer = e
        },
        // 关闭音频抽屉
        audioDra(e) {
            this.audioDrawer = e
        },
        // 文本合成弹框关闭回调
        handleCancle() {
            console.log('关闭咯')
            this.audioPopShow = true

            this.$refs.audioDrawerRefresh.getSyntheticAudioList()
        },
        openCancle() {
            this.audioPopShow = false
        },
        submit() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                if (!that.signinChecked) return that.$message.error('请阅读并同意克隆协议');
                if (!that.nameValue) return that.$message.error('请输入视频名称');
                var b = JSON.stringify(that.audioItem) == "{}";
                if (b == true) return that.$message.error('请选择音频');
                const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

                if (that.type !== 5) {
                    if (that.imageItem.id == undefined) return that.$message.error('请选择形象');
                    var data = {
                        name: that.nameValue,
                        model_id: that.imageItem.id,
                        line_id: that.type,
                        ...that.audioItem
                    }
                } else {
                    var data = {
                        name: that.nameValue,
                        source_video_url: that.videoPathOss,
                        line_id: that.type,
                        ...that.audioItem
                    }
                }
                createMyWork(data).then(res => {
                        if (res.data.code == 10000) {
                            loading.close();
                            that.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            setTimeout(() => {

                                that.$router.push({
                                    path: "/Home/myWork",
                                    query: { type: res.data.data.line_id }
                                });
                            }, 1200);
                        } else {
                            loading.close();
                        }
                    })
                that.time = null;
            }, 1000)
        },

        openAgreement() {
            this.agreementShow = true
        },
        // 同意协议
        consentAgreement() {
            this.signinChecked = true
            this.agreementShow = false
        },
    },
    created() {

    },
    watch: {
        type(newVal, oldVal) {
            this.imageItem = {}
        }
    }
}
</script>
<style lang="less" scoped>
.CreateVideo {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    height: 630px;
    position: relative;

    .header {
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #E6E6E6;
        display: flex;

        .model {
            font-size: 18px;
            color: #3D3D3D;
        }

        .points {
            margin-left: 20px;
            font-size: 14px;
            color: #CCCACA;
        }
        .warn{
            margin-left: 20px;
            font-size: 14px;
            color: #CCCACA;
        }
    }

    .borderBox {}

    .name {
        padding: 20px 0 0 20px;
        width: 480px;

        .title {
            font-size: 18px;
            color: #3D3D3D;
            margin-bottom: 10px;

            span {
                color: red;
            }
        }
    }

    .audioSelection {
        padding: 20px 0 0 20px;
        width: 475px;
        // border: 1px solid #000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 18px;
            color: #3D3D3D;
            margin-bottom: 10px;

            span {
                color: red;
            }
        }

        .selectAudio {
            display: flex;
            justify-content: space-between;

            .button {
                margin-top: 10px;
                padding: 5px 10px;
                text-align: center;
                color: #fff;
                border-radius: 5px;
                font-size: 18px;
                background-color: #3E7AFE;
                cursor: pointer;
                /*悬停变小手的属性*/
            }
        }


    }

    .v5 {
        margin: 20px;
        width: 500px;

        .backplaneVideo {
            margin-top: 10px;
            padding-bottom: 20px;

            .title {
                color: #3D3D3D;
                font-size: 18px;

                span {
                    color: red;
                }
            }

            .uploadBox {
                display: flex;
                margin-top: 10px;

                .upload_but {
                    width: 200px;
                    height: 200px;
                    border: 1px solid #ccc;
                    border-radius: 10px;

                    div {
                        display: flex;
                        justify-content: center;
                    }

                    .el-icon-upload {
                        text-align: center;
                        font-size: 50px;
                        color: #409EFF;
                    }

                    .el-upload__text {
                        font-size: 12px;
                    }
                }
            }

            .video {


                .videoBox {
                    display: flex;
                    justify-content: center;

                    video {
                        width: 521px;
                        height: 150px;
                    }
                }

                .reupload {
                    display: flex;
                    justify-content: center;

                    .text {
                        cursor: pointer;
                        /*悬停变小手的属性*/
                        width: 521px;
                        height: 36px;
                        line-height: 36px;
                        text-align: center;
                        color: #3E7AFE;
                        font-size: 20px;
                        background-color: #fff;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                        border-radius: 0px 0px 5px 5px;
                    }

                }
            }
        }
    }

    .imageSelection {
        padding: 20px 0 0 20px;
        width: 322px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 18px;
            color: #3D3D3D;
            padding-bottom: 10px;

            span {
                color: red;
            }

        }

        .positionBox:hover {
            border-radius: 5px;
            border: 1px dashed #409EFF;
        }

        .positionBox {

            border-radius: 5px;
            width: 358px;
            height: 178px;
            border: 1px dashed #ccc;
            text-align: center;
            color: #606266;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                width: 50px;
                height: 50px;
            }

            span {
                color: #409EFF;
            }
        }

        .button {
            margin-top: 20px;
            width: 322px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
            height: 34px;
            line-height: 34px;
            font-size: 18px;
            background-color: #3E7AFE;
            cursor: pointer;
            /*悬停变小手的属性*/
        }
    }

    /deep/.rtl {

        width: 1008px !important;
        padding: 0 20px;
    }


    /deep/.imageDrawer.rtl {
        width: 1208px !important;
        padding: 0 20px;
    }

    .submit {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60px;
        box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        z-index: 2;

        .but {
            margin-top: 10px;
            height: 20px;
            padding: 10px 20px;
            border-radius: 5px;
            color: #fff;
            background: #3E7AFE;
            margin-left: 30px;
            cursor: pointer;
        }

        .agreement {
            margin-left: 50px;
            color: #3D3D3D;
            line-height: 60px;



        }

        span {
            font-size: 14px;
            cursor: pointer;
            color: #3977F3;
        }
    }
}

/deep/.agreementShow {
    .text {
        overflow-y: auto;
        /* 出现垂直滚动条 */
        height: 500px;
    }

    border-radius: 10px;
}

/deep/.agreementShow::-webkit-scrollbar {
    .text::-webkit-scrollbar {
        display: none;
    }
}
</style>